<template>
    <div>
        <h1>I am from B component</h1>
        <div class="flex gap-2">
            <img src="https://via.placeholder.com/350x150">
            <img src="https://via.placeholder.com/350x150">
            <img src="https://via.placeholder.com/350x150">
            <img src="https://via.placeholder.com/350x150">
            <img src="https://via.placeholder.com/350x150">
            <img src="https://via.placeholder.com/350x150">
            <img src="https://via.placeholder.com/350x150">
            <img src="https://via.placeholder.com/350x150">
            <img src="https://via.placeholder.com/350x150">
            <img src="https://via.placeholder.com/350x150">
            <img src="https://via.placeholder.com/350x150">
            <img src="https://via.placeholder.com/350x150">
            <img src="https://via.placeholder.com/350x150">
            <img src="https://via.placeholder.com/350x150">
            <img src="https://via.placeholder.com/350x150">
            <img src="https://via.placeholder.com/350x150">
            <img src="https://via.placeholder.com/350x150">
            <img src="https://via.placeholder.com/350x150">
            <img src="https://via.placeholder.com/350x150">
            <img src="https://via.placeholder.com/350x150">
            <img src="https://via.placeholder.com/350x150">
            <img src="https://via.placeholder.com/350x150">
            <img src="https://via.placeholder.com/350x150">
            <img src="https://via.placeholder.com/350x150">
            <img src="https://via.placeholder.com/350x150">
            <img src="https://via.placeholder.com/350x150">
            <img src="https://via.placeholder.com/350x150">
            <img src="https://via.placeholder.com/350x150">
            <img src="https://via.placeholder.com/350x150">
            <img src="https://via.placeholder.com/350x150">
            <img src="https://via.placeholder.com/350x150">
            <img src="https://via.placeholder.com/350x150">
            <img src="https://via.placeholder.com/350x150">
            <img src="https://via.placeholder.com/350x150">
            <img src="https://via.placeholder.com/350x150">
            <img src="https://via.placeholder.com/350x150">
            <img src="https://via.placeholder.com/350x150">
            <img src="https://via.placeholder.com/350x150">
        </div>
    </div>
</template>

<script>
export default {

}
</script>